<script setup lang="ts">
import type { AddToCartOptions } from 'checkout/types/add-to-cart-options'
import type { PackageData } from 'gig/types/gig-details'

defineProps<{
  packages: PackageData[]
  options: {
    postOnSocial?: boolean
    extraDelivery?: boolean
    selectedPackage: PackageData
  }
}>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'changeSetting', value: AddToCartOptions): void
}>()

const changeSettings = (options: AddToCartOptions) => {
  emit('changeSetting', options)
}
</script>

<template>
  <Dialog
    :style="{ width: '881px' }"
    :breakpoints="{ '1024px': '375px' }"
    modal
    dismissable-mask
    :draggable="false"
    @hide="$emit('close')"
  >
    <h2 class="my-4 text-xl font-medium lg:mt-0">Compare Packages</h2>
    <GigComparePackagesTable
      :packages="packages"
      :options="{
        postOnSocial: options.postOnSocial,
        extraDelivery: options.extraDelivery,
        selectedPackage: options.selectedPackage,
      }"
      @change-setting="changeSettings"
    />
  </Dialog>
</template>
