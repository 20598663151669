<script setup lang="ts">
import type { AddToCartOptions } from 'checkout/types/add-to-cart-options'
import type { PackageData } from 'gig/types/gig-details'

const props = defineProps<{
  packages: PackageData[]
  options: {
    postOnSocial?: boolean
    extraDelivery?: boolean
    selectedPackage: PackageData
  }
}>()

const emit = defineEmits<{
  (e: 'changeSetting', value: AddToCartOptions): void
}>()

const selectedPackage = ref(props.options.selectedPackage)
const packageToggleModel: { [key: string]: boolean } = reactive(
  props.packages.reduce(
    (a, v) => ({ ...a, [v.name]: selectedPackage.value.id === v.id }),
    {},
  ),
)

const extraFastDeliveryModel: { [key: string]: boolean } = reactive(
  props.packages.reduce(
    (a, v) => ({
      ...a,
      [v.name]:
        selectedPackage.value.name === v.name
          ? props.options.extraDelivery
          : false,
    }),
    {},
  ),
)

const emitChangeSettings = () => {
  emit('changeSetting', {
    postOnSocial: false,
    extraDelivery: extraFastDeliveryModel[selectedPackage.value.name],
    selectedPackageId: selectedPackage.value.id,
  })
}

const updateExtraDeliveryStates = () => {
  for (const [key] of Object.entries(extraFastDeliveryModel)) {
    if (key !== selectedPackage.value.name) {
      extraFastDeliveryModel[key] = false
    }
  }
}

const changePackage = (gigPackage: PackageData) => {
  packageToggleModel[selectedPackage.value.name] = false
  selectedPackage.value = gigPackage

  emitChangeSettings()
  updateExtraDeliveryStates()
}
</script>

<template>
  <table
    class="mb-4 grid w-full border-collapse gap-4 lg:flex lg:gap-0 lg:border-r lg:border-t lg:border-gray-500"
  >
    <GigComparePackagesRow
      class="max-w-[162px]"
      hidden-on-mobile
    >
      <GigComparePackagesCell
        tag="th"
        scope="col"
      >
        Packages
      </GigComparePackagesCell>

      <GigComparePackagesCell
        tag="th"
        scope="col"
      >
        Price
      </GigComparePackagesCell>

      <GigComparePackagesCell
        tag="th"
        scope="col"
      >
        Revisions
      </GigComparePackagesCell>

      <GigComparePackagesCell
        tag="th"
        scope="col"
      >
        Delivery Time
      </GigComparePackagesCell>

      <GigComparePackagesCell
        tag="th"
        scope="col"
      >
        Post on Social
      </GigComparePackagesCell>

      <GigComparePackagesCell
        tag="th"
        scope="col"
      >
        Licensing Rights
      </GigComparePackagesCell>

      <GigComparePackagesCell
        tag="th"
        scope="col"
        visually-hidden
      >
        Select
      </GigComparePackagesCell>
    </GigComparePackagesRow>

    <GigComparePackagesRow
      v-for="gigPackage in packages"
      :key="gigPackage.name"
      :class="{ 'is-selected': gigPackage.name === selectedPackage.name }"
    >
      <GigComparePackagesCell
        tag="th"
        scope="row"
      >
        {{ gigPackage.name }}
      </GigComparePackagesCell>

      <GigComparePackagesCell
        scope="row"
        heading="Price"
        class="!justify-start py-6"
      >
        <p class="text-4xl">
          {{ $price(gigPackage.price) }}
        </p>
        <p
          v-if="gigPackage.description"
          class="text-overflow package-description color-gray max-w-[194px] break-words text-sm leading-5 tracking-wide text-gray-200"
        >
          {{ gigPackage.description }}
        </p>
      </GigComparePackagesCell>

      <GigComparePackagesCell
        scope="row"
        heading="Revisions"
      >
        {{ gigPackage.revisions }}
      </GigComparePackagesCell>

      <GigComparePackagesCell
        scope="row"
        heading="Delivery Time"
      >
        {{ gigPackage.deliveryTime }}
      </GigComparePackagesCell>

      <GigComparePackagesCell
        scope="row"
        heading="Post on Social"
      >
        <div
          class="flex flex-wrap items-center justify-center"
          :class="{ 'lg:justify-start': gigPackage.platforms.length > 5 }"
        >
          <template v-if="gigPackage.platforms.length">
            <Component
              :is="useIconComponent(getColorfulSocialIcon(item.toLowerCase()))"
              v-for="(item, index) in gigPackage.platforms"
              :key="index"
              :aria-label="item"
              class="mx-2 my-2 inline-flex"
              :height="24"
            />
          </template>
          <span v-else>No</span>
        </div>
      </GigComparePackagesCell>

      <GigComparePackagesCell
        scope="row"
        heading="Licensing Rights"
      >
        {{ gigPackage.licensingRights || 'None' }}
      </GigComparePackagesCell>

      <GigComparePackagesCell>
        <ToggleButton
          v-model="packageToggleModel[gigPackage.name]"
          on-label="Selected"
          off-label="Select"
          :disabled="gigPackage.name === selectedPackage.name"
          class="!border-blue-100"
          :class="{
            '!text-blue-100 opacity-100': gigPackage.id === selectedPackage.id,
            '!bg-blue-100 !text-white': gigPackage.id !== selectedPackage.id,
          }"
          @change="changePackage(gigPackage)"
        />
      </GigComparePackagesCell>
    </GigComparePackagesRow>
  </table>
</template>

<style scoped>
.package-description {
  word-break: break-word;
}
</style>
