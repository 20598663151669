<script setup lang="ts">
import { packageNames } from 'gig/data/package-names'

import type { PackageData } from 'gig/types/gig-details'
import type { AddToCartOptions } from 'checkout/types/add-to-cart-options'

import IconCalendarCheck from '@/assets/images/calendar-check.svg'
import IconChatCircle from '@/assets/images/chat-circle.svg'
import IconCheckCircleWavy from '@/assets/images/check-circle-wavy.svg'

const props = withDefaults(
  defineProps<{
    packages: PackageData[]
    isAddToCartVisible?: boolean
    isPackageSwitchVisibleForOneOption?: boolean
    isChatPage?: boolean
    resetAddToCartButtonMargin?: boolean
    userContext?: 'buyer' | 'creator'
  }>(),
  {
    isAddToCartVisible: true,
    isPackageSwitchVisibleForOneOption: true,
    userContext: 'buyer',
  },
)

defineEmits<{
  (e: 'addToCart', value: AddToCartOptions): void
}>()

const disabledPackageOptions = packageNames.map((packName) => ({
  name: packName,
}))
const packageOptions = computed<{ name: string }[] | PackageData[]>(() =>
  props.packages.length === 1
    ? disabledPackageOptions
    : props.packages
        .slice()
        .sort(
          (a, b) => packageNames.indexOf(a.name) - packageNames.indexOf(b.name),
        ),
)
const isPackageSwitchOptionsVisible = computed(() => {
  return (
    props.packages.length > 1 ||
    (props.packages.length === 1 && props.isPackageSwitchVisibleForOneOption)
  )
})
const selectedPackage = ref<PackageData>(props.packages[0])
const isComparePackagesModalLoaded = ref(false)
const isComparePackagesModalVisible = ref(false)

const { $price, $priceNumber } = useNuxtApp()
const feeFraction = 0.1
const fee = computed(
  () => ($priceNumber(selectedPackage.value.price) || 0) * feeFraction,
)
const creatorEarning = computed(() =>
  $price(selectedPackage.value.price || 0, undefined, fee.value),
)

const changeSettings = (options: AddToCartOptions) => {
  selectedPackage.value = props.packages.find(
    (item) => item.id === options.selectedPackageId,
  ) as PackageData
}

const showComparePackagesModal = () => {
  isComparePackagesModalLoaded.value = true
  isComparePackagesModalVisible.value = true
}

watch(
  () => props.packages,
  () => {
    selectedPackage.value = props.packages[0]
  },
)
</script>

<template>
  <div class="p-fluid flex flex-col">
    <slot />
    <SelectButton
      v-if="isPackageSwitchOptionsVisible"
      v-model="selectedPackage"
      :disabled="packages.length === 1"
      :options="packageOptions"
      :unselectable="true"
      option-label="name"
      class="pb-5"
    />
    <div class="flex justify-between pb-5 text-xl">
      <span v-if="userContext === 'buyer'">Collab</span>
      <span v-else>Collab Listing Price</span>
      <span>{{ $price(selectedPackage.price) }}</span>
    </div>
    <div
      v-if="userContext === 'creator'"
      class="mb-4 flex justify-between text-xs font-semibold tracking-wide"
    >
      <span class="relative">
        You will receive
        <FormTooltip
          text="This helps us operate our platform and offer 24/7 customer support for your orders."
        />
      </span>
      {{ creatorEarning }}
    </div>
    <div
      class="package-description break-words border-b border-gray-500 pb-5 leading-5 text-gray-200"
    >
      {{ selectedPackage.description }}
    </div>
    <div class="flex items-center border-b border-gray-500 py-5">
      <IconChatCircle
        class="mr-2"
        aria-hidden="true"
      />
      <span>
        {{ selectedPackage.revisions }}
        {{ selectedPackage.revisions === 1 ? 'Revision' : 'Revisions' }}
      </span>
    </div>
    <div class="flex items-center border-b border-gray-500 py-5">
      <IconCalendarCheck
        class="mr-2"
        aria-hidden="true"
      />
      <span>{{ selectedPackage.deliveryTime }} Delivery</span>
    </div>
    <div class="flex min-h-[65px] flex-wrap border-b border-gray-500 py-3">
      <span class="mr-[5px] mt-3 shrink-0">
        Post on Social{{ selectedPackage.platforms.length ? '' : ': No' }}
      </span>
      <div
        v-if="selectedPackage.platforms.length"
        class="my-px flex-1 items-center justify-center"
      >
        <Component
          :is="useIconComponent(getColorfulSocialIcon(item.toLowerCase()))"
          v-for="(item, index) in selectedPackage.platforms"
          :key="index"
          :aria-label="item"
          class="mx-[5px] my-2 inline-flex"
          :height="24"
        />
      </div>
    </div>
    <div
      v-if="
        selectedPackage.licensingRights &&
        selectedPackage.licensingRights !== 'None'
      "
      class="flex items-center border-b border-gray-500 py-5"
    >
      <IconCheckCircleWavy
        class="mr-2 w-[22px]"
        aria-hidden="true"
      />
      <span>{{ selectedPackage.licensingRights }} licensing rights</span>
    </div>
    <div class="mt-5 flex flex-1 flex-col">
      <Button
        v-if="isAddToCartVisible"
        label="Purchase"
        :class="[
          'p-button mt-auto',
          {
            'p-button-rounded': !isChatPage,
            'mb-2': !resetAddToCartButtonMargin,
          },
        ]"
        @click="
          $emit('addToCart', {
            selectedPackageId: selectedPackage.id,
          })
        "
      />
      <Button
        v-if="isAddToCartVisible && isPackageSwitchOptionsVisible"
        :disabled="packages.length === 1"
        label="Compare Packages"
        :class="[
          'p-button',
          {
            'p-button-rounded p-button-text ': !isChatPage,
            'p-button-outlined': isChatPage,
          },
        ]"
        @click="showComparePackagesModal"
      />
      <GigDetailsComparePackagesModal
        v-if="
          isAddToCartVisible &&
          isComparePackagesModalLoaded &&
          packages.length > 1
        "
        v-model:visible="isComparePackagesModalVisible"
        :packages="packages"
        :options="{
          selectedPackage,
          postOnSocial: true,
        }"
        @close="isComparePackagesModalVisible = false"
        @change-setting="changeSettings"
      />
      <slot name="bottom" />
    </div>
  </div>
</template>

<style scoped>
:deep(.p-disabled .p-button-label) {
  @apply text-gray-400;
}

.package-description {
  word-break: break-word;
}
</style>
